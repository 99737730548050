<template>
  <div class="mediaReportDetailChart">
    <template v-if="loading">
      <LoadingPlaceholder />
    </template>
    <template v-else>
      <div class="m-form__group form-group">
        <div class="m-radio-inline">
          <label class="m-radio">
            <input
              v-model="days"
              type="radio"
              :name="'mediaReportDetailChartRadios' + spotId"
              value="61"
            >2 {{ $t('months') }}
            <span />
          </label>
          <label class="m-radio">
            <input
              v-model="days"
              type="radio"
              :name="'mediaReportDetailChartRadios' + spotId"
              value="31"
            >1 {{ $t('month') }}
            <span />
          </label>
          <label class="m-radio">
            <input
              v-model="days"
              type="radio"
              :name="'mediaReportDetailChartRadios' + spotId"
              value="14"
            >2 {{ $t('weeks') }}
            <span />
          </label>
          <label class="m-radio">
            <input
              v-model="days"
              type="radio"
              :name="'mediaReportDetailChartRadios' + spotId"
              value="7"
            >1 {{ $t('week') }}
            <span />
          </label>
        </div>
      </div>
      <template v-if="reportsAvailable">
        <slot />
      </template>
      <template v-else>
        {{ $t('noDataAvailable') }}
      </template>
    </template>
  </div>
</template>

<script>
import { dateTimeMixin } from '@/mixins/dateTimeMixin.js'
export default {
  name: "MediaReportDetailChart",
  mixins: [
    dateTimeMixin
  ],
  props: {
    installationId: {
      type: String,
      required: true
    },
    spotId: {
      type: Number,
      required: true
    },
    isMovie: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      reportData: null,
      loading: true,
      days: 7,
      categories: null,
      tooltiptemplate: '#= series.name #: #= value #',
      noPhotoRuns: 0,
      noSpeedRuns: 0,
      totalRuns: 0,
      totalGood: 0,
      totalMedium: 0,
      totalBad: 0,
      totalUnknown: 0,
      totalTimingModeOne: 0,
      totalRaceStarted: 0,
      totalRaceFinished: 0,
      totalObjectCaught: 0,
      totalObjectLost: 0,
      totalTimeout: 0,
      totalIsFaulty: 0,
      totalTimingMode: 0,
      totalVirtualStopwatch: 0
    }
  },
  computed: {
    reportsAvailable: function () {
      if (this.reportData && this.reportData.length > 0) {
        return true;
      }
      return false;
    }
  },
  watch: {
    days: function () {
      if(this.isMovie) {
        this.fetchDataV4();
      }
      else {
        this.fetchData();
      }
    }
  },
  created: function () {
    if(this.isMovie) {
        this.fetchDataV4();
      }
      else {
        this.fetchData();
      }
  },
  methods: {
    fetchData () {
      let apiURL = `/Media/GetDetailedReportWithSpotId/${ this.installationId }/${ this.days }/${ this.spotId }`;
      this.axios.get(apiURL)
      .then((response) => {
        this.reportData = response.data;

        this.noPhotoRuns = this.reportData.map(o => o.noPhotoRuns);
        this.noSpeedRuns = this.reportData.map(o => o.noSpeedRuns);
        this.totalRuns = this.reportData.map(o => o.totalRuns);

        this.categories = this.reportData.map(o => this.$options.filters.dateTime_date(o.createDate));
        this.loading = false;
      })
      .catch(() => {
        this.loading = false;
      });
    },
    fetchDataV4 () {
      this.loading = true;
      let apiURL = `/Media/GetV4ReportWithSpotId/${ this.installationId }/${ this.days }/${ this.spotId }`;
      this.axios.get(apiURL)
      .then((response) => {
        this.reportData = response.data;

        this.totalRuns = this.reportData.map(o => o.totalRuns);
        this.totalGood = this.reportData.map(o => o.totalGood);
        this.totalMedium = this.reportData.map(o => o.totalMedium);
        this.totalBad = this.reportData.map(o => o.totalBad);
        this.totalUnknown = this.reportData.map(o => o.totalUnknown);
        this.totalTimingModeOne = this.reportData.map(o => o.totalTimingModeOne);
        this.totalRaceStarted = this.reportData.map(o => o.totalRaceStarted);
        this.totalRaceFinished = this.reportData.map(o => o.totalRaceFinished);
        this.totalObjectCaught = this.reportData.map(o => o.totalObjectCaught);
        this.totalObjectLost = this.reportData.map(o => o.totalObjectLost);
        this.totalTimeout = this.reportData.map(o => o.totalTimeout);
        this.totalIsFaulty = this.reportData.map(o => o.totalIsFaulty);
        this.totalTimingMode = this.reportData.map(o => o.totalTimingMode);
        this.totalVirtualStopwatch = this.reportData.map(o => o.totalVirtualStopwatch);

        this.categories = this.reportData.map(o => this.$options.filters.dateTime_date(o.createDate));
        this.loading = false;
      })
      .catch(() => {
        this.loading = false;
      });
    }
  }
}
</script>